import Vue from "vue";
import Router from "vue-router";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import authRoutes from "../auth/routes";
import appRoutes from "./routes";

["push", "replace"].forEach((method) => {
  const originalMethod = Router.prototype[method];
  Router.prototype[method] = function m(location) {
    return originalMethod.call(this, location).catch((error) => {
      if (error.name !== "NavigationDuplicated") {
        // capture exception
      }
    });
  };
});

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  routes: [
    ...authRoutes,
    ...appRoutes,
    {
      name: "root",
      path: "/",
      redirect: { name: "app" },
    },
  ],
});

import NProgress from "nprogress";
import "nprogress/nprogress.css";

router.beforeEach((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
