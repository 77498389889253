<template>
  <div class="panel panel-default">
    <div class="panel-body text-center">
      <weka-spinner v-if="loading"></weka-spinner>

      <error-box v-else-if="error" :error="error" title="Oh no"></error-box>
      <div v-else>
        <p style="color: #888">Local Weka Home</p>
        <p>
          <router-link
            :to="{ name: 'lwh', params: { version: lwh.id } }"
            class="btn btn-lg btn-success"
            style="width: 220px"
            tag="a"
          >
            <div>
              {{ lwh.id }}
            </div>
            <div style="font-size: 75%; color: #e3ffe3; font-weight: 300">
              Released {{ lwh.created_at | moment("from") }}
            </div>
          </router-link>
        </p>
        <div class="small">
          <a
            href="https://docs.weka.io/monitor-the-weka-cluster/the-wekaio-support-cloud/local-weka-home-deployment"
            target="_blank"
          >
            <font-awesome-icon
              :icon="['fas', 'arrow-up-right-from-square']"
              class="fa-fw"
            ></font-awesome-icon>
            Documentation
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

function initialData() {
  return {
    loading: true,
    error: null,
    lwh: null,
  };
}

export default {
  name: "local-weka-home",
  data: initialData,

  async mounted() {
    try {
      const res = await axios.get("/api/v1/lwh", {
        params: { page_size: 1, exists: true },
      });

      if (res.data.num_results === 0) {
        this.error = "Could not find the latest release";
      } else {
        this.lwh = res.data.objects[0];
      }
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  },
};
</script>
