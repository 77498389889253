<template>
  <modal ref="theModal" @done="close" modal-class="modal-lg">

    <div slot="header">
      <h3>
        Cluster Details
      </h3>
    </div>

    <div slot="body">
      <div v-if="cluster">
        <cluster :cluster="cluster" />
      </div>
    </div>

    <div class="text-right" slot="footer">
      <a class="btn btn-default" @click.prevent="close">
        Close
      </a>
    </div>

  </modal>
</template>

<script>
 function initialData() {
     return {
         cluster: null,
     }
 }

 export default {
     name: 'cluster-details',

     data: initialData,

     methods: {
         show(cluster) {
             Object.assign(this, initialData())
             this.cluster = cluster
             this.$refs.theModal.showModal()
         },
         close() {
             this.$refs.theModal.hideModal()
         },
     }
 }
</script>
