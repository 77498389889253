<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-8">
        <greeting></greeting>
      </div>
      <div class="col-sm-4">
        <latest-release></latest-release>
        <management-station></management-station>
        <local-weka-home></local-weka-home>
        <free-trial-status></free-trial-status>
        <entitlement-summary></entitlement-summary>
      </div>
    </div>
  </div>
</template>

<script>
 import LocalWekaHome      from './local-weka-home';
 import Greeting           from './greeting'
 import LatestRelease      from './latest-release'
 import ManagementStation  from './management-station'
 import FreeTrialStatus    from './free-trial-status'
 import EntitlementSummary from './entitlement-summary'

 export default {
     name: 'dashboard',

     components: {
         LocalWekaHome,
         Greeting,
         LatestRelease,
         FreeTrialStatus,
         EntitlementSummary,
         ManagementStation,
     }
 }
</script>
