export default [
    {
        name      : 'lwh',
        path      : 'lwh',
        component : () => import(/* webpackChunkName: "app.lwh" */ './lwh'),
        redirect  : { name: 'lwh:download' },
        children  : [
            {
                name      : 'lwh:download',
                path      : 'download',
                component : () => import(/* webpackChunkName: "app.lwh.download" */ './download-lwh'),
            },

            {
                name      : 'lwh:notes',
                path      : 'notes',
                component : () => import(/* webpackChunkName: "app.lwh.notes" */ './lwh-notes'),
            },
        ],
    }
]
