<template>
  <nav class="navbar navbar-static-top navbar-weka-portal">
    <div class="container">
      <div class="navbar-header">
        <button
          aria-expanded="false"
          class="navbar-toggle collapsed"
          data-target="#bs-example-navbar-collapse-1"
          data-toggle="collapse"
          type="button"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link :to="{ name: 'app' }">
          <img id="navbar-brand" src="../assets/weka-logo.svg" />
        </router-link>
      </div>

      <div id="bs-example-navbar-collapse-1" class="collapse navbar-collapse">
        <ul class="nav navbar-nav">
          <router-link
            :to="{ name: 'dashboard' }"
            active-class="active"
            tag="li"
          >
            <a>
              <font-awesome-icon
                :icon="['fas', 'fa-gauge-high']"
                class="fa-fw"
              ></font-awesome-icon>
              Dashboard
            </a>
          </router-link>
          <router-link
            :to="{ name: 'releases' }"
            active-class="active"
            tag="li"
          >
            <a>
              <font-awesome-icon
                :icon="['fas', 'fa-download']"
                class="fa-fw"
              ></font-awesome-icon>
              Releases
            </a>
          </router-link>
          <router-link :to="{ name: 'wms' }" active-class="active" tag="li">
            <a>
              <font-awesome-icon
                :icon="['fas', 'fa-box']"
                class="fa-fw"
              ></font-awesome-icon>
              WMS
            </a>
          </router-link>
          <router-link :to="{ name: 'cdm' }" active-class="active" tag="li">
            <a>
              <font-awesome-icon
                :icon="['fas', 'fa-cloud-meatball']"
                class="fa-fw"
              ></font-awesome-icon>
              CDM
            </a>
          </router-link>
          <router-link :to="{ name: 'lwh' }" active-class="active" tag="li">
            <a>
              <font-awesome-icon
                :icon="['fas', 'fa-house-laptop']"
                class="fa-fw"
              ></font-awesome-icon>
              LWH
            </a>
          </router-link>
          <router-link
            :to="{ name: 'licenses' }"
            active-class="active"
            tag="li"
          >
            <a>
              <font-awesome-icon
                :icon="['far', 'fa-file-lines']"
                class="fa-fw"
              ></font-awesome-icon>
              Licenses
            </a>
          </router-link>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li v-if="hasAdminPrivileges">
            <a href="/admin">
              <font-awesome-icon
                :icon="['fas', 'fa-shield-halved']"
                class="fa-fw"
              ></font-awesome-icon>
            </a>
          </li>
          <router-link :to="{ name: 'account' }" active-class="active" tag="li">
            <a>
              <img :src="gravatarUrl" class="profile-picture" />
              {{ curUser.display_name }}
            </a>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import gravatar from "gravatar";

export default {
  name: "top-bar",

  computed: {
    curUser() {
      return this.$store.getters.currentUser;
    },
    hasAdminPrivileges() {
      return this.$store.getters.hasAdminPrivileges;
    },
    gravatarUrl() {
      return gravatar.url(this.curUser.emails[0], {
        protocol: "https",
        s: 64,
        d: "mm",
      });
    },
  },
};
</script>
