export default [
  {
    name: "cdm",
    path: "cdm",
    component: () => import(/* webpackChunkName: "app.wms" */ "./cdm"),
    redirect: { name: "cdm:download" },
    children: [
      {
        name: "cdm:download",
        path: "download",
        component: () =>
          import(
            /* webpackChunkName: "app.releases.release.download" */ "./download-cdm"
          ),
      },

      {
        name: "cdm:notes",
        path: "notes",
        component: () =>
          import(
            /* webpackChunkName: "app.releases.release.notes" */ "./cdm-notes"
          ),
      },
    ],
  },
];
