export default [
  {
    name: "licenses",
    path: "licenses",
    redirect: { name: "licensing:licenses" },
    component: () => import(/* webpackChunkName: "app.licensing" */ "./main"),
    children: [
      {
        name: "licensing:licenses",
        path: "licenses",
        component: () =>
          import(
            /* webpackChunkName: "app.licensing.licenses" */ "./classic/licenses"
          ),
      },
      {
        name: "licensing:create",
        path: "create",
        component: () =>
          import(
            /* webpackChunkName: "app.licensing.create" */ "./classic/entitlements"
          ),
      },
      {
        name: "licensing:usage-reports",
        path: "usage-reports",
        component: () =>
          import(
            /* webpackChunkName: "app.licensing.usage-reports" */ "./usage-reports/main"
          ),
      },
    ],
  },
];
