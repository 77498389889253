import store from "../store";
import App from "./app";
import Dashboard from "./dashboard/main";
import releasesRoutes from "./releases/routes";
import licensesRoutes from "./licenses/routes";
import accountRoutes from "./account/routes";
import wmsRoutes from "./wms/routes";
import lwhRoutes from "./lwh/routes";
import cdmRoutes from "./cdm/routes";

export default [
  {
    name: "app",
    path: "/ui",
    component: App,
    redirect: { name: "dashboard" },
    async beforeEnter(to, from, next) {
      await store.dispatch("loadUser");
      if (store.getters.isAuthenticated) {
        if (store.getters.isPending) {
          const path = window.location.pathname;
          const query = !path || path === "/" ? undefined : { next: path };
          next({ name: "pending", query });
        } else {
          next();
        }
      } else {
        const path = window.location.pathname;
        const query = !path || path === "/" ? undefined : { next: path };
        next({ name: "login", query });
      }
    },
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        component: Dashboard,
      },
      ...releasesRoutes,
      ...wmsRoutes,
      ...lwhRoutes,
      ...cdmRoutes,
      ...licensesRoutes,
      ...accountRoutes,
    ],
  },
];
