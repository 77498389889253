<template>
  <modal ref="theModal" @done="done">

    <div slot="header">
      <h3>
        Hide Cluster
      </h3>
    </div>

    <div slot="body">
      <div v-if="cluster">
        <div class="alert alert-danger">
          <p>
            <strong>
              <font-awesome-icon :icon="['fas', 'fa-warning']" class="fa-fw"></font-awesome-icon>
              You are about to hide this cluster from your clusters view
            </strong>
          </p>
          <p>
            Note that if the cluster becomes online again, it will become visible in your list of clusters.
          </p>
        </div>
        <ul class="list-group">
          <li class="list-group-item">
            <cluster-row :cluster="cluster"></cluster-row>
          </li>
        </ul>
        <p>
          Are you sure you want to continue?
        </p>
      </div>
      <error-box title="Oh no" :error="error"></error-box>
    </div>

    <div class="text-right" slot="footer">
      <a class="btn btn-link" @click.prevent="close" :disabled="working">
        Cancel
      </a>
      <button type="submit" class="btn btn-danger" :disabled="working">
        Hide Cluster
        <weka-spinner :inline="true" v-if="working" color="white"></weka-spinner>
      </button>
    </div>

  </modal>
</template>

<script>
 import axios from 'axios'

 function initialData() {
     return {
         cluster : null,         
         working : false,
         error   : null,
     }
 }

 export default {
     name: 'hide-cluster',

     data: initialData,

     methods: {
         show(cluster) {
             Object.assign(this, initialData())
             this.cluster = cluster
             this.$refs.theModal.showModal()
         },
         close() {
             this.$refs.theModal.hideModal()
         },
         async done() {
             this.working = true
             try {
                 await axios.put(`/api/v1/system/${this.cluster.id}`, {
                     hidden: true,
                 })
                 this.$emit('done')
                 this.close()
             } catch (error) {
                 this.error = error
                 this.working = false
             }
         }
     }
 }
</script>
