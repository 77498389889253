export default [
    {
        name: 'account',
        path: 'account',
        redirect: { name: 'account:profile' },
        component: () => import(/* webpackChunkName: "app.account" */ './main'),
        children: [
            {
                name: 'account:profile',
                path: 'profile',
                component: () => import(/* webpackChunkName: "app.account.profile" */ './profile'),
            },
            {
                name: 'account:notifications',
                path: 'notifications',
                component: () => import(/* webpackChunkName: "app.account.notifications" */ './notifications/main'),
            },
            {
                name: 'account:api-tokens',
                path: 'api-tokens',
                component: () => import(/* webpackChunkName: "app.account.api-tokens" */ './api-tokens/main'),
            },
            {
                name: 'account:team',
                path: 'team',
                component: () => import(/* webpackChunkName: "app.account.team" */ './team/main'),
            },
            {
                name: 'account:misc',
                path: 'misc',
                component: () => import(/* webpackChunkName: "app.account.team" */ './misc'),
            },
        ]
    },
]
