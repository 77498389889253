export default [
    {
        name      : 'wms',
        path      : 'wms',
        component : () => import(/* webpackChunkName: "app.wms" */ './wms'),
        redirect  : { name: 'wms:download' },
        children  : [
            {
                name      : 'wms:download',
                path      : 'download',
                component : () => import(/* webpackChunkName: "app.releases.release.download" */ './download-wms'),
            },

            {
                name      : 'wms:notes',
                path      : 'notes',
                component : () => import(/* webpackChunkName: "app.releases.release.notes" */ './wms-notes'),
            },
        ],
    }
]
