<template>
  <div class="panel panel-default" v-if="inTrial">
    <div class="panel-body">
      <p class="text-center" style="color:#888;">
        Free Trial
      </p>

      <div class="alert alert-info text-center">
        <div>
          Your free trial is active until
        </div>
        <div>
          <strong>
            {{ trialEndDate }}
          </strong>
        </div>
      </div>

      <small>
        <p>
          During this time:
        </p>
        <ul style="padding-left:2rem;">
          <li>
            <p>
              You will not be charged for clusters in a <router-link :to="{ name: 'licensing:subscription' }">subscription plan</router-link>
            </p>
          </li>
          <li>
            <p>
              You can <router-link :to="{ name: 'licensing:create' }">create licenses</router-link>
              until the end of your trial period
            </p>
          </li>
        </ul>
      </small>
    </div>
  </div>
</template>

<script>
 import moment from 'moment'

 export default {
     name: 'free-trial-status',

     computed: {
         curUser() {
             return this.$store.getters.currentUser
         },
         inTrial() {
             return this.curUser.org && moment().isBefore(this.curUser.org.free_trial_until)
         },
         trialEndDate() {
             return moment(this.curUser.org.free_trial_until).format('LL')
         },
     },
 }
</script>
