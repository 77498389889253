<template>
  <div v-if="!loading && remaining" class="panel panel-default">
    <div class="panel-body text-center">
      <p style="color:#888;">
        Outstanding Entitlements
      </p>

      <p>
        <table class="right-padded-cells">
          <tr v-if="remaining.drive_capacity_gb">
            <td class="text-right">
              {{ remaining.drive_capacity_gb * 1000000000 | prettyBytes }}
            </td>
            <td class="text-left">
              <strong>
                Raw Flash capacity
              </strong>
            </td>
          </tr>
            <tr v-if="remaining.usable_capacity_gb">
                <td class="text-right">
                    {{ remaining.usable_capacity_gb * 1000000000 | prettyBytes }}
                </td>
                <td class="text-left">
                    <strong>
                        Usable Flash capacity
                    </strong>
                </td>
            </tr>
            <tr v-if="remaining.obs_capacity_gb">
                <td class="text-right">
                    {{ remaining.obs_capacity_gb * 1000000000 | prettyBytes }}
                </td>
                <td class="text-left">
                    <strong>
                        OBS capacity - Data Tiering
                    </strong>
                </td>
            </tr>
            <tr v-if="remaining.obs_capacity_for_data_protection_gb">
                <td class="text-right">
                    {{ remaining.obs_capacity_for_data_protection_gb * 1000000000 | prettyBytes }}
                </td>
                <td class="text-left">
                    <strong>
                        OBS capacity - Data Protection
                    </strong>
                </td>
            </tr>
          <tr v-if="remaining.xps_tb">
            <td class="text-right">
              {{ remaining.xps_tb * TB_TO_B | prettyBytes }}
            </td>
            <td class="text-left">
              <strong>
                {{ SKUs.WekaXPS }}
              </strong>
            </td>
          </tr>
          <tr v-if="remaining.xcl_tb">
            <td class="text-right">
              {{ remaining.xcl_tb * TB_TO_B | prettyBytes }}
            </td>
            <td class="text-left">
              <strong>
                {{ SKUs.WekaXCL }}
              </strong>
            </td>
          </tr>
          <tr v-if="remaining.xos_tb">
            <td class="text-right">
              {{ remaining.xos_tb * TB_TO_B | prettyBytes }}
            </td>
            <td class="text-left">
              <strong>
                {{ SKUs.WekaXOS }}
              </strong>
            </td>
          </tr>
            <tr v-if="remaining.wpsw_tb">
                <td class="text-right">
                    {{ remaining.wpsw_tb * TB_TO_B | prettyBytes }}
                </td>
                <td class="text-left">
                    <strong>
                        {{ SKUs.WekaWPSW }}
                    </strong>
                </td>
            </tr>
          <tr v-if="remaining.dpo_tb">
            <td class="text-right">
              {{ remaining.dpo_tb * TB_TO_B | prettyBytes }}
            </td>
            <td class="text-left">
              <strong>
                {{ SKUs.WekaDPO }}
              </strong>
            </td>
          </tr>
            <tr v-if="remaining.dto_tb">
                <td class="text-right">
                    {{ remaining.dto_tb * TB_TO_B | prettyBytes }}
                </td>
                <td class="text-left">
                    <strong>
                        {{ SKUs.WekaDTO }}
                    </strong>
                </td>
            </tr>
          <tr v-if="remaining.deo_tb">
            <td class="text-right">
              {{ remaining.deo_tb * TB_TO_B | prettyBytes }}
            </td>
            <td class="text-left">
              <strong>
                {{ SKUs.WekaDEO }}
              </strong>
            </td>
          </tr>
        </table>
      </p>

      <div class="small">
        <router-link :to="{ name: 'licensing:create' }" tag="a">
          Create a license
        </router-link>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
 table.right-padded-cells {
     margin-left: auto;
     margin-right: auto;
     float: none;
     line-height:2.75rem;

     tr {
         td {
             padding-right: 1rem;
         }
     }
 }
</style>

<script>
 import { SKUs, TB_TO_B, isEntitlementExpired } from '@/utils';
 import { getAll } from '../../objects/get-all'

 export default {
     name: 'entitlement-summary',

     data() {
         return {
             loading   : true,
             error     : null,
             remaining : null,
         }
     },

     computed: {
       SKUs() {
         return SKUs
       },
         TB_TO_B() {
         return TB_TO_B
       },
         curUser() {
             return this.$store.getters.currentUser
         },
     },

     async mounted() {
         try {
             const entitlements = await getAll({ url: '/api/v1/entitlements', params: { org_id: this.curUser.org_id } })
             const remaining = {
               drive_capacity_gb : 0,
               usable_capacity_gb: 0,
               obs_capacity_gb : 0,
               obs_capacity_for_data_protection_gb : 0,
               xps_tb: 0,
               xcl_tb: 0,
               xos_tb: 0,
               wpsw_tb: 0,
               dpo_tb: 0,
               dto_tb: 0,
               deo_tb: 0,
             }

           entitlements.forEach(ent => {
               for (const key of Object.keys(remaining)) {
                   if(!isEntitlementExpired(ent)) {
                       remaining[key] += ent.remaining[key] || 0
                   }
               }
           })

           if (Object.keys(remaining).some(key => remaining[key] > 0)) {
             this.remaining = remaining
           }
         } catch (error) {
             this.error = error
         }
         this.loading = false
     }
 }
</script>
